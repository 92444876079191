<template>
  <div class="content__body">
    <div class="statistics">
      <div v-if="stats" class="statistics__title">Общая:</div>
      <div v-if="stats" class="statistics__items">
        <div class="statistics__item">
          <div class="statistics__info">
            <div class="statistics__count">{{ stats.items_count }}</div>
            <p class="statistics__desc">Товаров</p>
          </div>
        </div>
        <div class="statistics__item statistics__item--neutral">
          <div class="statistics__info">
            <div class="statistics__count">{{ stats.clients_count }}</div>
            <p class="statistics__desc">Клиентов</p>
          </div>
        </div>
        <div class="statistics__item">
          <div class="statistics__info">
            <div class="statistics__count">{{ stats.users_count }}</div>
            <p class="statistics__desc">Пользователей</p>
          </div>
        </div>
        <div class="statistics__item statistics__item--success">
          <div class="statistics__info">
            <div class="statistics__count">{{ stats.orders_count }}</div>
            <p class="statistics__desc">Заказов</p>
          </div>
        </div>
        <div class="statistics__item statistics__item--abort">
          <div class="statistics__info">
            <div class="statistics__count">{{ Math.round(stats.orders_total_sum) }} ₽</div>
            <p class="statistics__desc">Сумма заказов</p>
          </div>
        </div>
      </div>
      <div v-if="stats_users" class="statistics__title">Пользователи:</div>
      <div v-if="stats_users" class="statistics__items">
        <div class="statistics__item statistics__item--neutral">
          <div class="statistics__info">
            <div class="statistics__count">{{ stats_users.clients_count }}</div>
            <p class="statistics__desc">Клиентов</p>
          </div>
        </div>
        <div class="statistics__item statistics__item--neutral">
          <div class="statistics__info">
            <div class="statistics__count">{{ stats_users.client_users_count }}</div>
            <p class="statistics__desc">Пользователей клиентов</p>
          </div>
        </div>
        <div class="statistics__item statistics__item--neutral">
          <div class="statistics__info">
            <div class="statistics__count">{{ stats_users.client_users_active_count }}</div>
            <p class="statistics__desc">Пользователей клиентов активных (с токеном)</p>
          </div>
        </div>
        <div class="statistics__item">
          <div class="statistics__info">
            <div class="statistics__count">{{ stats_users.manager_users_rostov_count }}</div>
            <p class="statistics__desc">Пользователей менеджеров Ростов</p>
          </div>
        </div>
        <div class="statistics__item">
          <div class="statistics__info">
            <div class="statistics__count">{{ stats_users.manager_users_stav_count }}</div>
            <p class="statistics__desc">Пользователей менеджеров Ставрополь</p>
          </div>
        </div>
        <div class="statistics__item">
          <div class="statistics__info">
            <div class="statistics__count">{{ stats_users.manager_users_count }}</div>
            <p class="statistics__desc">Пользователей менеджеров активных (с токеном)</p>
          </div>
        </div>
      </div>
      <div v-if="stats_by_days" class="statistics__title">По дням:</div>
      <div v-if="stats_by_days" class="statistics__filters">
        <div class="statistics__select">
          <v-select
            @input="onSetPeriod"
            label="id"
            v-model="filter_result"
            :reduce="org => org.id"
            :searchable="false"
            :clearable="false"
            :options="filter_options"
            placeholder="Выберите период"
            class="select"
          >
            <template slot="open-indicator">
              <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
              </svg>
            </template>
            <template slot="no-options">Выберите период</template>
            <template slot="option" slot-scope="option">
              <div class="select__item d-center">
                {{ option.name }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.name }}
              </div>
            </template>
          </v-select>
        </div>
        <form v-if="filter_result === 6" class="form" @submit.prevent="fetchStatsByDays(date_start, date_end)">
          <div class="form__content">
            <div class="form-control statistics__input">
              <masked-input
                v-model="date_start"
                name="date_start"
                mask="11.11.1111"
                placeholder="дд.мм.гггг"
                class="form-control__input"
                id="date_start"
                autocomplete="off"
              />
            </div>
            <div class="form-control statistics__input">
              <masked-input
                v-model="date_end"
                name="date_start"
                mask="11.11.1111"
                placeholder="дд.мм.гггг"
                class="form-control__input"
                id="date_end"
                autocomplete="off"
              />
            </div>
            <button
              class="button button--mini"
              type="submit"
              :disabled="!startDateWrong"
            >
              Применить
            </button>
          </div>
        </form>
      </div>
      <div v-if="stats_by_days" class="statistics__items">
        <div class="statistics__item statistics__item--neutral">
          <div class="statistics__info">
            <div class="statistics__count">{{ stats_by_days.new_client_users }}</div>
            <p class="statistics__desc">Новых пользователей-клиентов</p>
          </div>
        </div>
        <div class="statistics__item">
          <div class="statistics__info">
            <div class="statistics__count">{{ stats_by_days.new_manager_users }}</div>
            <p class="statistics__desc">Новых пользователей-менеджеров</p>
          </div>
        </div>
        <div class="statistics__item statistics__item--success">
          <div class="statistics__info">
            <div class="statistics__count">{{ stats_by_days.orders_count }}</div>
            <p class="statistics__desc">Всего заказов</p>
          </div>
        </div>
        <div class="statistics__item statistics__item--abort">
          <div class="statistics__info">
            <div class="statistics__count">{{ Math.round(stats_by_days.orders_total_sum) }} ₽</div>
            <p class="statistics__desc">Сумма заказов</p>
          </div>
        </div>
        <div class="statistics__item statistics__item--success">
          <div class="statistics__info">
            <div class="statistics__count">{{ stats_by_days.clients_orders_count }}</div>
            <p class="statistics__desc">Заказов от клиентов</p>
          </div>
        </div>
        <div class="statistics__item statistics__item--abort">
          <div class="statistics__info">
            <div class="statistics__count">{{ Math.round(stats_by_days.clients_orders_total_sum) }} ₽</div>
            <p class="statistics__desc">Сумма от клиентов</p>
          </div>
        </div>
        <div class="statistics__item statistics__item--success">
          <div class="statistics__info">
            <div class="statistics__count">{{ stats_by_days.managers_orders_count }}</div>
            <p class="statistics__desc">Заказов от менеджеров</p>
          </div>
        </div>
        <div class="statistics__item statistics__item--abort">
          <div class="statistics__info">
            <div class="statistics__count">{{ Math.round(stats_by_days.managers_orders_total_sum) }} ₽</div>
            <p class="statistics__desc">Сумма от менеджеров </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaskedInput from 'vue-masked-input'
import { format, startOfYesterday, subDays } from 'date-fns'

export default {
  name: 'StatisticsList',
  components: {
    MaskedInput,
  },
  data() {
    return {
      stats: {},
      stats_users: {},
      stats_by_days: {},
      date_start: '',
      date_end: '',
      filter_result: 1,
      filter_options: [
        { id: 1, name: 'Весь период' },
        { id: 2, name: 'Сегодня' },
        { id: 3, name: 'Вчера' },
        { id: 4, name: 'За 7 дней' },
        { id: 5, name: 'За 30 дней' },
        { id: 6, name: 'Произвольный период' },
      ]
    }
  },
  computed: {
    startDateWrong() {
      return this.date_start.replaceAll('_', '').length === 10
    },
  },
  mounted() {
    this.$store.dispatch('dict/GET_STATISTICS')
      .then(response => {
        this.stats = response.data
      })
    this.$store.dispatch('dict/GET_STATISTICS_USERS')
      .then(response => {
        this.stats_users = response.data
      })
    this.fetchStatsByDays()
  },
  methods: {
    fetchStatsByDays(from = '', to = '') {
      this.$store.dispatch('dict/GET_STATISTICS_BY_DAYS', { from, to })
        .then(response => {
          this.stats_by_days = response.data
        })
    },
    onSetPeriod() {
      if (this.date_start || this.date_end) {
        this.date_start = ''
        this.date_end = ''
      }
      switch (this.filter_result) {
      case 1:
        this.fetchStatsByDays()
        break
      case 2:
        this.fetchStatsByDays(format(new Date(), 'dd.MM.yyyy'))
        break
      case 3:
        this.fetchStatsByDays(format(startOfYesterday(), 'dd.MM.yyyy'), format(startOfYesterday(), 'dd.MM.yyyy'))
        break
      case 4:
        this.fetchStatsByDays(format(subDays(new Date(), 7), 'dd.MM.yyyy'))
        break
      case 5:
        this.fetchStatsByDays(format(subDays(new Date(), 30), 'dd.MM.yyyy'))
        break
      }
    },
  }
}
</script>
